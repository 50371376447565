import { useState } from 'react'
import emailjs from 'emailjs-com'
import Recaptcha from "react-google-recaptcha";

const initialState = {
  name: '',
  email: '',
  telefone: '',
  cond: '',
  message: '',
  reCAPTCHA: '',
}

export const Contact = (props) => {
  const [{ name, email, telefone, cond, message }, setState] = useState(initialState);
  const [isVerified, setisVerified] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => {
    window.location.reload();
  }

const recaptchaLoaded = () => {
  console.log("Captch is reloaded")
}


const verifyCaptcha = (response) => {
    if(response)
    {
      setisVerified(true)
    }
  }

  const handleSubmit = (e) => {


      e.preventDefault()
      console.log(name, email, telefone, cond, message)
      emailjs
        .sendForm(
          'service_cyhmux4', 'template_oyohm7v', e.target, 'XseHJRig76mkWCzxA'
        )
        .then(
          (result) => {
            console.log(result.text)
            alert("Sua solicitalão foi envaida com sucesso!!")
            clearState()
          },
          (error) => {
            console.log(error.text)
            alert("Por favor, verifique primeiro se você é um ser humano")
          }
        )

  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>SOLICITE UM ORÇAMENTO!</h2>
                <p>
                Solicitar um atendimento! Escolha a melhor parceira para a seu condomínio. {isVerified}
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Nome'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='E-mail'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='tel'
                        id='telefone'
                        name='telefone'
                        className='form-control'
                        placeholder='(xx) xxxxx-xxxx'
                        mask='(99) 99999-9999'
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='cond'
                        name='cond'
                        className='form-control'
                        placeholder='Condominio'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Mensagem'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <Recaptcha
                        /* # Local # sitekey="6Le0TO8cAAAAAKOvQR_ZzckeS0MM3M4l-xtoHJk4" */
                                sitekey="6Lc3WO0cAAAAAB_s0M88f3nrbJYy1Ia233VnEwL5"
                                render="explicit"
                                verifyCallback={verifyCaptcha}
                                onloadCallback={recaptchaLoaded}
                    />
                <button type='submit' className='btn btn-custom btn-lg'>
                  Enviar agora
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3></h3>

            </div>
            <div className='contact-item'>
              <p>
                <span>

                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : '/'}>
                      <i className='fa fa-instagram'></i>
                    </a>
                  </li>

                  <li>
                    <a href={props.data ? props.data.whatsapp : '/'}>
                      <i className='fa fa-whatsapp'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; GTD CONDOMÍNIOS 2021.
          </p>
        </div>
      </div>
    </div>
  )
}


